<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div v-loading="loading" class="brands container brands-view-container">
    <div class="content-bar">
      <principal-bar
        :title="`Total brands (${totalBrand})`"
      ></principal-bar>
      <div class="az">
        <el-button
          class="fw-bold btn-primary"
          size="medium" @click="handleCLickAZ">{{ az }}
        </el-button>
      </div>
    </div>
    <div class="search-table">
      <!-- <span>Search</span> -->
      <el-input
        v-model="searchString"
        clearable
        size="medium"
        prefix-icon="el-icon-search"
        placeholder="Search by brand name"/>
    </div>

    <template v-if="brands.length > 0">
        <div v-if="isValidPermission('BRAND_LIST')"
              class="cards">
          <principal-bar
            v-if="isValidPermission('BRAND_CREATE')"
            :buttonsList="(isValidPermission('BRAND_CREATE'))
       ? buttonsList : null"
          ></principal-bar>
          <el-card
            v-for="brand in brands"
            :key="brand.uuid"
            shadow="hover"
            :body-style="{ padding: '0px', border: 'none' }"
          >
            <!-- <avatar class="image" :radius="10" :fullname="brand.name" :size="80"></avatar> -->
            <div class="content-image-header">
              <img width="100%" :src="getBrandLogo(brand.logo)"
                   class="image img-brand-size"
                   alt="img">
              <div
                v-if="isValidPermission('BRAND_EDIT')"
                class="cursor-pointer settings-icon">
                <i @click="goToSettings(brand.id)" class="mdi mdi-cog-outline"></i>
              </div>
            </div>

            <div class="foot">
              <div class="button clearfix">
                <div>
                  <p class="fw-bold text-center">{{ brand.name }}</p>
                </div>
                <div>
                  <el-button
                    v-if="isValidPermission('REPORT_LIST')"
                    class="btn-secondary-reports"
                    @click="goToReport(brand)"
                    size="medium">Reports
                  </el-button>
                </div>
              </div>
            </div>
          </el-card>
        </div>

    </template>

    <el-empty v-else :image-size="200"></el-empty>

    <brand-modal v-if="showModalCreate"
                 :showModal="showModalCreate"
                 :create="true"
                 title="Create Brand">
    </brand-modal>

    <brand-modal v-if="showModalSettings"
                 :showModal="showModalSettings"
                 :settings="true"
                 title="Brand Settings"
                 :brandId="brandId">
    </brand-modal>
  </div>
</template>

<script>
import ApiMILO from '@/lib/ApiMILO';
import { mapState, mapGetters } from 'vuex';
import BrandModal from '@/components/Modals/BrandModal.vue';
import ImgNotFound from '@/assets/img_not_found.png';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import _ from 'lodash';
// import Avatar from 'vue-avatar-component';

export default {
  name: 'BrandsView',
  components: {
    // Avatar
    PrincipalBar,
    BrandModal,
  },
  data() {
    return {
      searchString: '',
      debounceSearch: null,
      buttonsList: [
        {
          text: 'Create New Brand',
          actionType: 'showModalCreate',
          icon: 'mdi-plus',
        },
      ],
      loading: true,
      showModalCreate: false,
      showModalSettings: false,
      brands: [],
      currentDate: new Date(),
      brandId: '',
      paginator: {
        currentPage: 0,
        pageSize: 15,
        total: 0,
        page: 1,
      },
      az: 'a-z',
      totalBrand: 0,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
  },
  watch: {
    searchString() {
      this.debounceSearch();
    },
  },
  methods: {
    load() {
      if (this.paginator.pageSize <= this.paginator.total) {
        this.paginator.pageSize += 5;
        this.getBrands();
      }
    },
    getBrands(search = null, orderBy = null, orderDirection = null) {
      this.loading = true;
      let paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;
      if (orderBy !== null && orderDirection !== null) {
        paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}&order_by=${orderBy}&order_direction=${orderDirection}`;
      }

      let url = `/brands?${paginate}`;
      if (search) {
        url = `/brands?${paginate}&search=${search}`;
      }

      ApiMILO.get(url).then((r) => {
        this.loading = false;
        this.brands = Object.values(r.data);
        this.totalBrand = r?.paginator?.count ?? 0;
        if (r.paginator.total_pages) {
          this.paginator.currentPage = r.paginator.page;
          this.paginator.page_size = r.paginator.page_size;
          this.paginator.total = r.paginator.count;
        }
      }).catch((e) => {
        this.loading = true;
        console.log(e);
      });
    },
    goToReport(brand) {
      const { id } = brand;
      this.$router.push({ path: `brands/${id}/reports` });
    },
    closeModal() {
      this.showModalCreate = false;
      this.showModalSettings = false;
    },
    goToSettings(brandId) {
      this.$router.push({ path: `/brands/${brandId}` });
    },
    getBrandLogo(logo) {
      if (logo) {
        return logo;
      }
      return ImgNotFound;
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getBrands();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getBrands();
    },
    openModalCreate() {
      this.showModalCreate = true;
    },
    emitSearch() {
      this.getBrands(this.searchString);
    },
    handleCLickAZ() {
      if (this.az === 'a-z') {
        this.getBrands(null, 'name', 'asc');
        this.az = 'z-a';
      } else {
        this.getBrands(null, 'name', 'desc');
        this.az = 'a-z';
      }
    },
    scrollSite() {
      window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (scrollPosition + windowHeight >= documentHeight) {
          this.load();
        }
      });
    },
    activeScroll() {
      // if (document.body.scrollHeight <= window.innerHeight) {}
      this.load();
    },
  },
  mounted() {
    window.addEventListener('beforeunload', () => {
      window.scrollTo(0, 0);
    });
    // this.scrollSite();
    this.getBrands();
    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadBrands', this.getBrands);
    this.$root.$on('handleScrollMove', this.activeScroll);

    this.debounceSearch = _.debounce(this.emitSearch, 500);
  },
};
</script>

<style lang="scss">
.brands {
  // max-width: 1090px;
  // margin: 50px auto auto auto;

  &.container.brands-view-container {
    max-width: 1390px;
  }

  .principal-bar {
    gap: 0;
  }

  margin-top: 54px;
  margin-bottom: 70px;

  .search-table {
    width: 100%;
    margin: 30px 0 25px;

    span {
      color: #868686;
      font-weight: 700;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 7px;
    }

    .el-input--medium {
      font-size: 16px !important;
    }

    .el-input--medium .el-input__icon {
      color: #ECECEC;
      font-size: 16px !important;
    }

    input {
      border: 2px solid #CBD2E0;
      border-radius: 6px;
    }
  }

  .cards {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    // max-width: 1000px;
    margin: 36px auto auto auto;
    justify-content: start;
    @media (max-width: 768px) {
      justify-content: center;
    }
    @mixin respond-to($from: 'small') {
      margin: auto;
    }

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .el-card {
      background: #F7F8FF;
      width: 251.12px;
      height: 274px;
      border: none;
      // border: 1px solid !important;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px #E6E6E6;
      border-radius: 6px;

      .foot {
        margin: 5px 0 15px;

        .fw-bold {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }

      .settings-icon {
        transition: all $durationAnimation;
        position: absolute;
        top: 5px;
        right: 5px;
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);

        &:hover {
          color: #A4A7AC;
        }

        i {
          font-size: 25px;
        }
      }
    }
  }

  .title {
    color: #214BEB;
    font-size: 24px;
    font-weight: bold;
  }

  .img-brand-size {
    min-width: 170px;
    max-height: 129px;
    height: 129px;
    object-fit: contain;
    max-width: 200px;
    margin: 16px 0;
  }

  .btn-secondary-reports {
    width: 162.38px;
    height: 32px;
    padding: 0;
  }

  .btn-secondary {
    width: 251.12px;
    height: 274px;
    background-color: #FFFFFF;
    border: 2px;
    border-color: #3FB5E5;
    border-style: dashed;
    box-shadow: 0px 0px 12px #E6E6E6;
    border-radius: 6px;
    @mixin respond-to($from:'small') {
      margin: 5px 0 0 5px;
    }

    span {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      row-gap: 20px;
      align-content: center;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #3FB5E5;
      text-decoration: underline;

      i {
        font-size: 70px;
        width: 84px;
        height: 84px;
        background-color: #F1FBFF;
        border-radius: 4px;
        margin: auto;
        padding-top: 10px;
        color: #3FB5E5;
      }
    }
  }

  .content-image-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-bar {
    display: flex;
    justify-content: space-between;

    .az {
      display: flex;
      justify-content: end;
      margin-top: 10px;

      button {
        text-transform: capitalize;
      }
    }
  }
}
</style>
